import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  extends: TablePage,
  components: {

  },
  name: 'config_list',
  data() {
    return {

    };
  },
  created() {
    this.getConfigList('page_list');
  },

  methods: {
    modalClick({ val, row }) {
      console.log(val);
      console.log(row);
      // if (val.code === 'stopHeader') {
      //     console.log(this.selection);
      //     if (!this.selection || this.selection.length === 0) {
      //         return this.$message.error('请至少选择一条数据');
      //     }
      //     request.post('/cps/agreeShare/stopBatch', { ids: this.selection }).then((res) => {
      //         console.log(res);
      //         if (res.success) {
      //             this.$message.success(res.message);
      //             this.getList();
      //         }
      //     });
      // }
    },
  },
};
